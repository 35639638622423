import Handlebars from 'handlebars'
import inlineCSS from 'inline-css'

import { Brand, Order } from '../../store/orders/types'
import { OrderItem } from '../../store/items/types'
import { Client, Upsell } from '../../store/clients/reducer'
import { CurrentMessage } from '../../store/currentMessage/types'
import { UserInfo } from '../../store/user/reducers'
import { draftjsEditorStateToHTML, StateToHTMLOptionsArgs } from '../draftjs'

// Import all the helpers
import firstName from './helpers/firstName'
import defaultProductName from './helpers/defaultProductName'
import formatDate from './helpers/formatDate'
import formatRevisionEndDate from './helpers/formatRevisionEndDate'
import accountPageURL from './helpers/accountPageURL'
import callTime from './helpers/callTime'
import i18nHandlebars from './helpers/i18n'
import messagePreview from './helpers/messagePreview'
import compare from './helpers/compare'
import portalURL from './helpers/portalURL'
import callURL from './helpers/callURL'
import scheduleURL from './helpers/scheduleURL'
import scheduledCallTime from './helpers/scheduledCallTime'
import scheduledTime from './helpers/scheduledTime'
import append from './helpers/append'
import { Document } from '../../store/documents/types'
import discountToken from './helpers/discountToken'
import discountPercent from './helpers/discountPercent'
import purchaseUrl from './helpers/purchaseUrl'
import CIOMagicLink from './helpers/CIOMagicLink'

export interface HandlebarsOrderContext {
  brand: Brand | null
  client: Client
  order: Order | null
  user: UserInfo | null
  scheduledItem: OrderItem | null
  awaitingSchedulingPhoneCall: OrderItem | null
  hasAccountLink: boolean
  inPortal: boolean
  i18next: object
  attachments: Document[] | null
  useFileURL?: boolean
  useStagingFileURL?: boolean
  docsInEmail?: boolean | null
  directScheduling?: {
    day: string
    time: string
    serviceName: string
    customerNote: string
  }
  upsells: Upsell[]
  snippetName: string
}

export interface HandlebarsUserContext {
  brand: Brand | null
  client: Client | null
  expert: UserInfo
  order: Order | null
  user: UserInfo | null
  hasAccountLink: false
  inPortal: false
  i18next: object
  snippetName: string
}

export type HandlebarsContextType = HandlebarsOrderContext | HandlebarsUserContext

// Register Handlebars helper functions

// Helper function that takes in a full customer name and will trim it so that only the first name is returned
Handlebars.registerHelper('firstName', firstName)

// Helper function that will appropriately capitalize Resume and CV in an email
Handlebars.registerHelper('defaultProductName', defaultProductName)

// Helper function that formats a date according to the language format string
Handlebars.registerHelper('formatDate', formatDate)

// Helper function that will format the revision period end date
Handlebars.registerHelper('formatRevisionEndDate', formatRevisionEndDate)

// Helper function that fetches the Onboarding Questionnaire/Account link
Handlebars.registerHelper('accountPageURL', accountPageURL)

// Helper function that fetches the scheduled call time for a call
Handlebars.registerHelper('callTime', callTime)

// Helper function that allows for translations
Handlebars.registerHelper('i18n', i18nHandlebars)

// Helper function that creates a preview of the message body
Handlebars.registerHelper('messagePreview', messagePreview)

// Helper function that allows for easy comparison of values
Handlebars.registerHelper('compare', compare)

// Helper function that will create a URL to the Portal together
Handlebars.registerHelper('portalURL', portalURL)

// Helper function that will create a URL to a phone call
Handlebars.registerHelper('callURL', callURL)

// Helper function that will create a URL to Portal Home Page from where the customer can schedule the call
Handlebars.registerHelper('scheduleURL', scheduleURL)

// Helper function that will format the time for a call
Handlebars.registerHelper('scheduledCallTime', scheduledCallTime)

// Helper function that will format the time for a call
Handlebars.registerHelper('scheduledTime', scheduledTime)

// Helper function that will concentrate strings together
// @ts-ignore
Handlebars.registerHelper('append', append)

Handlebars.registerHelper('purchaseUrl', purchaseUrl)

Handlebars.registerHelper('discountToken', discountToken)

Handlebars.registerHelper('discountPercent', discountPercent)

Handlebars.registerHelper('CIOMagicLink', CIOMagicLink)

export const renderEmailTemplate = (template: string, context: HandlebarsContextType): string => {
  const handlebarsTemplate = Handlebars.compile(template)
  return handlebarsTemplate(context)
}

export const renderEmail = async (
  context: HandlebarsContextType,
  message: CurrentMessage,
  renderArgs?: StateToHTMLOptionsArgs
): Promise<string> => {
  const messageBody = draftjsEditorStateToHTML(message.editorState, renderArgs || {})
  const updatedMessage = { ...message, message_text: messageBody }
  // The email template has been split out into it's own file so that the browser can better cache it, as it doesn't
  // change that often. Typescript does not like this syntax that much, tho.
  // @ts-ignore
  const emailTemplate = await import('../../handlebars/email-template.hbs')

  // Because the message input is style unaware, we must inline some CSS rules into the document. HTML emails are so
  // much fun!
  return await inlineCSS(
    emailTemplate.default({
      ...context,
      message: updatedMessage,
      hasAccountLink: context.hasAccountLink && (!renderArgs || !renderArgs.disableLinks),
    }),
    {
      url: window.location.origin,
      applyStyleTags: true,
      removeStyleTags: false,
    }
  )
}
