import { PaginationArgs } from '../../utils/pagination'
import { LoadedLoadingErrorState } from '../../utils/state'

export interface TemplateState {
  snippets: SnippetList
  snippetsMeta: LoadedLoadingErrorState
  templates: TemplateList
  templatesMeta: LoadedLoadingErrorState
}

export interface Template {
  id: number
  context: string
  type: string
  language: string
  is_enabled: boolean
  subject: string
  body: string
  created_at: string
  updated_at: string
}

export enum TemplateTypes {
  FirstDraft = 'First Draft',
  Revision1 = '1st Revision',
  RevisionN = '2nd Revision',
  Introduction = 'Introduction',
  CloseOrder = 'Close Order',
  CallSummary = 'Call Summary',
  LinkedIn = 'LinkedIn',
  Reschedule = 'Reschedule Message',
  CloseOrderCIO = 'Close Order Cio',
  ResumeEditIntro = 'Resume Edit Intro',
  ResumeEditFarewell = 'Resume Edit Farewell',
}

export interface TemplateList {
  [template_id: number]: Template
}

export interface TemplateFilters extends PaginationArgs {
  language?: string
  type?: string
  context?: 'client' | 'user' | 'server'
  enabled?: boolean
  all?: boolean
}

export interface Snippet {
  id: number
  type: string
  name: string
  language: string
  body: string
  created_at: string
  updated_at: string
}

export interface SnippetList {
  [snippet_id: number]: Snippet
}

export interface SnippetFilters extends TemplateFilters {
  user_id?: number
}
