import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Client, Upsell } from './reducer'
import { ClientProductsMetadata } from './types'
import { FetchOrdersSuccess, FetchOrderSuccess } from '../orders/actions'
import {
  AxiosMiddlewareAction,
  AxiosMiddlewareActionSuccess,
  AxiosMiddlewareActionFail,
  AxiosMiddlewareActionCreator,
} from '../../utils/axios'
import { PaginationArgs } from '../../utils/pagination'
import { FetchClientOrdersSuccess, FetchUserAggregatedOrdersSuccess } from '../clientOrderAggregate/actions'
import { MessageStatusCreateSuccess, MessageStatusEndSuccess } from '../messages/actions'
import { ClientInfoUpdate } from '../../components/OrderFamily/OrderDetails/ClientDetails'
import { AppState } from '..'

export enum ClientsActionTypes {
  // Fetch multiple clients
  FETCH_CLIENTS = 'FETCH_CLIENTS',
  FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS',
  FETCH_CLIENTS_FAIL = 'FETCH_CLIENTS_FAIL',
  // Fetch one client
  FETCH_CLIENT = 'FETCH_CLIENT',
  FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS',
  FETCH_CLIENT_FAIL = 'FETCH_CLIENT_FAIL',
  // Fetch the product's metadata for the client
  FETCH_CLIENT_METADATA = 'FETCH_CLIENT_METADATA',
  FETCH_CLIENT_METADATA_SUCCESS = 'FETCH_CLIENT_METADATA_SUCCESS',
  FETCH_CLIENT_METADATA_FAIL = 'FETCH_CLIENT_METADATA_FAIL',

  UPDATE_CLIENT = 'UPDATE_CLIENT',
  UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS',
  UPDATE_CLIENT_FAIL = 'UPDATE_CLIENT_FAIL',

  FETCH_UPSELL_DATA = 'FETCH_UPSELL_DATA',
  FETCH_UPSELL_DATA_SUCCESS = 'FETCH_UPSELL_DATA_SUCCESS',
  FETCH_UPSELL_DATA_FAIL = 'FETCH_UPSELL_DATA_FAIL',

  FETCH_CIO_MAGIC_LINK = 'FETCH_CIO_MAGIC_LINK',
  FETCH_CIO_MAGIC_LINK_SUCCESS = 'FETCH_CIO_MAGIC_LINK_SUCCESS',
  FETCH_CIO_MAGIC_LINK_FAIL = 'FETCH_CIO_MAGIC_LINK_FAIL',
}

// The following action types are centered around fetching multiple clients
export interface FetchClients extends AxiosMiddlewareActionCreator {
  type: typeof ClientsActionTypes.FETCH_CLIENTS
}

export interface FetchClientsStart extends AxiosMiddlewareAction {
  type: typeof ClientsActionTypes.FETCH_CLIENTS
}

export interface FetchClientsSuccess extends AxiosMiddlewareActionSuccess<Client[], FetchClients> {
  type: typeof ClientsActionTypes.FETCH_CLIENTS_SUCCESS
}

export interface FetchClientsFail extends AxiosMiddlewareActionFail<FetchClients> {
  type: typeof ClientsActionTypes.FETCH_CLIENTS_FAIL
}

export function fetchClients(filters: PaginationArgs = {}): FetchClients {
  return {
    type: ClientsActionTypes.FETCH_CLIENTS,
    payload: {
      request: {
        url: '/v1/clients',
        params: filters,
      },
    },
  }
}

// The following events are centered around fetching a single client
export interface FetchClient extends AxiosMiddlewareActionCreator {
  clientID: number
  type: typeof ClientsActionTypes.FETCH_CLIENT
}

export interface FetchClientStart extends AxiosMiddlewareAction {
  clientID: number
  type: typeof ClientsActionTypes.FETCH_CLIENT
}

export interface FetchClientSuccess extends AxiosMiddlewareActionSuccess<Client, FetchClient> {
  type: typeof ClientsActionTypes.FETCH_CLIENT_SUCCESS
}

export interface FetchClientFail extends AxiosMiddlewareActionFail<FetchClient> {
  type: typeof ClientsActionTypes.FETCH_CLIENT_FAIL
}

export function fetchClient(clientID: number): FetchClient {
  return {
    clientID: clientID,
    type: ClientsActionTypes.FETCH_CLIENT,
    payload: {
      request: {
        url: `/v1/clients/${clientID}`,
      },
    },
  }
}

export interface FetchClientMetadata extends AxiosMiddlewareActionCreator {
  type: typeof ClientsActionTypes.FETCH_CLIENT_METADATA
  clientID: number
}

export interface FetchClientMetadataSuccess
  extends AxiosMiddlewareActionSuccess<ClientProductsMetadata, FetchClientMetadata> {
  type: typeof ClientsActionTypes.FETCH_CLIENT_METADATA_SUCCESS
}

export interface FetchClientMetadataFail extends AxiosMiddlewareActionFail<FetchClientMetadata> {
  type: typeof ClientsActionTypes.FETCH_CLIENT_METADATA_FAIL
}

export function fetchClientMetadata(clientID: number): FetchClientMetadata {
  return {
    clientID,
    type: ClientsActionTypes.FETCH_CLIENT_METADATA,
    payload: {
      request: {
        url: `/v1/clients/${clientID}/metadata`,
      },
    },
  }
}
export interface UpdateClientInfo extends AxiosMiddlewareActionCreator {
  type: typeof ClientsActionTypes.UPDATE_CLIENT
  clientID: number
}

export interface UpdateClientInfoSuccess extends AxiosMiddlewareActionSuccess<Client, UpdateClientInfo> {
  type: typeof ClientsActionTypes.UPDATE_CLIENT_SUCCESS
}

export interface UpdateClientInfoFail extends AxiosMiddlewareActionSuccess<UpdateClientInfo> {
  type: typeof ClientsActionTypes.UPDATE_CLIENT_FAIL
}

export function updateClient(clientID: number, clientInfoUpdate: ClientInfoUpdate): UpdateClientInfo {
  return {
    clientID,
    type: ClientsActionTypes.UPDATE_CLIENT,
    payload: {
      request: {
        url: `/v1/clients/${clientID}`,
        method: 'PATCH',
        data: clientInfoUpdate,
      },
    },
  }
}

export interface FetchUpsellData extends AxiosMiddlewareActionCreator {
  type: typeof ClientsActionTypes.FETCH_UPSELL_DATA
  clientID: number
}

export interface FetchUpsellDataSuccess extends AxiosMiddlewareActionSuccess<Upsell[], FetchUpsellData> {
  type: typeof ClientsActionTypes.FETCH_UPSELL_DATA_SUCCESS
}

export interface FetchUpsellDataFail extends AxiosMiddlewareActionFail<FetchUpsellData> {
  type: typeof ClientsActionTypes.FETCH_UPSELL_DATA_FAIL
}

export function fetchUpsell(
  clientID: number,
  products_user_id: number,
  discountToken: string,
  upsell_type: string
): FetchUpsellData {
  let url = process.env.REACT_APP_TALENTINC_API ?? ''

  if (process.env.REACT_APP_BUILD_TYPE !== 'production') {
    url = url.replace('https://', 'https://staging-')
  }

  return {
    clientID,
    type: ClientsActionTypes.FETCH_UPSELL_DATA,
    payload: {
      request: {
        url: `${url}/v2/users/${products_user_id}/upsells`,
        params: {
          dt: discountToken,
          type: upsell_type,
        },
      },
    },
  }
}

export interface FetchCIOMagicLink extends AxiosMiddlewareActionCreator {
  type: typeof ClientsActionTypes.FETCH_CIO_MAGIC_LINK
  clientID: number
}

export interface FetchCIOMagicLinkSuccess
  extends AxiosMiddlewareActionSuccess<{ url: string; order_completed: boolean }, FetchCIOMagicLink> {
  type: typeof ClientsActionTypes.FETCH_CIO_MAGIC_LINK_SUCCESS
}

export interface FetchCIOMagicLinkFail extends AxiosMiddlewareActionFail<FetchCIOMagicLink> {
  type: typeof ClientsActionTypes.FETCH_CIO_MAGIC_LINK_FAIL
}

export function fetchCIOMagicLink(
  clientID: number,
  productsUserID: number,
  productsOrderID: number,
  ttlDay?: number
): FetchCIOMagicLink {
  return {
    clientID,
    type: ClientsActionTypes.FETCH_CIO_MAGIC_LINK,
    payload: {
      request: {
        url: `/v2/users/${productsUserID}/magic-link`,
        method: 'POST',
        data: {
          order_id: productsOrderID,
          ttl_days: ttlDay ?? 30,
        },
      },
    },
  }
}

export function fetchCIOMagicLinkInIntervals(
  clientID: number,
  productsUserID: number,
  productsOrderID: number,
  ttlDay?: number
): ThunkAction<Promise<void>, AppState, {}, AnyAction> {
  return async function(dispatch: ThunkDispatch<{}, {}, AnyAction>, getState: () => AppState) {
    let retryCount = 0
    let response

    const delay = () => {
      return new Promise<FetchCIOMagicLinkSuccess>((resolve, reject) =>
        setTimeout(async function() {
          try {
            const res = ((await dispatch(
              fetchCIOMagicLink(clientID, productsUserID, productsOrderID, ttlDay)
            )) as unknown) as FetchCIOMagicLinkSuccess
            resolve(res)
          } catch (error) {
            reject(error)
          }
        }, 2000)
      )
    }

    response = await delay()

    while (response?.payload?.data?.order_completed === false && retryCount < 5) {
      retryCount += 1
      response = await delay()
    }

    return
  }
}

export type ClientsActionType =
  | FetchClient
  | FetchClientsStart
  | FetchClientsSuccess
  | FetchClientsFail
  | FetchClientStart
  | FetchClientSuccess
  | FetchClientFail
  | FetchOrdersSuccess
  | FetchOrderSuccess
  | FetchUserAggregatedOrdersSuccess
  | FetchClientOrdersSuccess
  | FetchClientMetadata
  | FetchClientMetadataSuccess
  | FetchClientMetadataFail
  | MessageStatusCreateSuccess
  | MessageStatusEndSuccess
  | UpdateClientInfo
  | UpdateClientInfoSuccess
  | UpdateClientInfoFail
  | FetchUpsellData
  | FetchUpsellDataSuccess
  | FetchUpsellDataFail
  | FetchCIOMagicLink
  | FetchCIOMagicLinkSuccess
  | FetchCIOMagicLinkFail
